import { ApolloClient } from '@apollo/client';
import { blinkConstants } from '@thoughtspot/blink-constants';
import {
    BlinkContextOptions,
    Dispatcher,
    EmbedContextOptions,
    useEmbedContext,
} from '@thoughtspot/blink-context';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { Parameter } from '/@services/generated/graphql-types';
import { AnswerPageConfigInterface } from '../services/answer/answer.util';
import { overrideBrowserFetchAndXhrForToken } from './browserOverrideForFetchAndXhr.util';
import { overrideBrowserWindowOpen } from './browserOverrideForWindowOpen';
import {
    EmbedParams,
    eventErrorType,
    EventStatus as EventStatusType,
    getEmbedQueryParams,
    isEmbeddedApp as isEmbedCheck,
} from './embed-base.util';

// TODO: merge with event types from Embed SDK NPM module
/**
 * These are events which are triggered by the TS App, they
 * are handled by the Embed SDK.
 */
export const EventType = blinkConstants.embedEvents;
export const IS_SEARCH_EMBED = 'isSearchEmbed';
export const IS_LIVEBOARD_EMBED = 'isLiveboardEmbed';
export const IS_FULL_APP_EMBED = 'isFullAppEmbed';
export const IS_SEARCH_WORKSHEET_SELECTOR_HIDDEN = 'hideWorksheetSelector';
export const IS_SAGE_EMBED = 'isSageEmbed';
export const HIDE_SEARCH_BAR_TITLE = 'hideSearchBarTitle';
export const HIDE_SAGE_ANSWER_HEADER = 'hideSageAnswerHeader';
export const DISABLE_WORKSHEET_CHANGE = 'disableWorksheetChange';
export const IS_PRODUCT_TOUR = 'isProductTour';
export const HIDE_EUREKA_SUGGESTIONS = 'hideEurekaSuggestions';
export const IS_SEARCH_BAR_HIDDEN = 'hideSearchBar';
export const IS_LINKOVERRIDE_ENABLED = 'linkOverride';
export const IS_PINBOARD_VIZ_EMBED = '/embed/viz';
export const TRUSTED_AUTH_COOKIELESS = 'AuthServerCookieless';
export const ADDITIONAL_PENDO_KEY = 'additionalPendoKey';
export const isEmbeddedApp = isEmbedCheck;
export const EventStatus = EventStatusType;
const FIRST_BREAKPOINT_BREAKPOINT_VARIABLE =
    '--ts-var-liveboard-dual-column-breakpoint';
const SECOND_BREAKPOINT_BREAKPOINT_VARIABLE =
    '--ts-var-liveboard-single-column-breakpoint';
export const FIRST_BREAKPOINT_DEFAULT_VALUE = 1024;
export const SECOND_BREAKPOINT_DEFAULT_VALUE = 630;
export const BREAKPOINT_LOWER_BOUND = 500;

export enum EmbedEventConstants {
    Status = 'status',
}

/**
 * These are events which are triggered by the EmbedSDK
 * on the TS app.
 */
export enum IncomingEventType {
    Drilldown = 'triggerDrillDown',
    Search = 'search',
    SetVisibleVizs = 'SetPinboardVisibleVizs',
    SetVisibleTabs = 'SetPinboardVisibleTabs',
    SetHiddenTabs = 'SetPinboardHiddenTabs',
    UpdateRuntimeFilters = 'UpdateRuntimeFilters',
    Refresh = 'refresh',
    GetTML = 'getTML',
    GetAnswerSession = 'getAnswerSession',
    ResetSearch = 'resetSearch',
    OpenFilter = 'openFilter',
    AddColumns = 'addColumns',
    RemoveColumn = 'removeColumn',
    Navigate = 'Navigate',
    GetFilters = 'getFilters',
    UpdateFilters = 'updateFilters',
    GetTabs = 'getTabs',
    UpdateCrossFilter = 'UpdateCrossFilter',
    ResetLiveboardPersonalisedView = 'ResetLiveboardPersonalisedView',
    UpdateSageQuery = 'updateSageQuery',
    AskSage = 'AskSage',
    GetParameters = 'GetParameters',
    UpdateParameters = 'UpdateParameters',
    GetIframeUrl = 'GetIframeUrl',
}

export enum HomeLeftNavItems {
    SEARCH_DATA = 'search-data',
    HOME = 'insights-home',
    LIVEBOARDS = 'liveboards',
    ANSWERS = 'answers',
    MONITOR_ALERTS = 'monitor-alerts',
    SPOTIQ_ANALYSIS = 'spotiq-analysis',
}

export enum HomepageModule {
    Search = 'SEARCH',
    Watchlist = 'WATCHLIST',
    Favorite = 'FAVORITE',
    MyLibrary = 'MY_LIBRARY',
    Trending = 'TRENDING',
    Learning = 'LEARNING',
}

export interface HostConfig {
    hostUserGuid: string;
    hostClusterId: string;
    hostClusterName: string;
}

// TODO: merge with event types from Embed SDK NPM module
export enum DataSourceVisualMode {
    Hidden = 'hide',
    Collapsed = 'collapse',
    Expanded = 'expand',
}

// Export events used by api.js
export enum ExportEvents {
    THOUGHTSPOT_AUTH_EXPIRED = 'ThoughtspotAuthExpired',
    EXPORT_VIZ_DATA_TO_PARENT = 'exportVizDataToParent',
    ALERT = 'alert',
    EXPORT_VIZ_DATA_TO_CHILD = 'exportVizDataToChild',
    GET_DATA = 'getData',
    GET_EXPORT_REQUEST_FOR_CURRENT_PINBOARD = 'getExportRequestForCurrentPinboard',
}

// TODO: merge with action ids from Embed SDK NPM module
export enum Action {
    AddScenario = 'addScenario',
    Edit = 'edit',
    Pin = 'pin',
    Share = 'share',
    Save = 'save',
    EditACopy = 'editACopy',
    SaveAsView = 'saveAsView',
    ShowUnderlyingData = 'showUnderlyingData',
    AnswerDelete = 'onDeleteAnswer',
    MoveToTab = 'onContainerMove',
    DownloadAsPng = 'downloadAsPng',
    DownloadAsCsv = 'downloadAsCSV',
    DownloadAsPdf = 'downloadAsPdf',
    DownloadAsXlsx = 'downloadAsXLSX',
    Download = 'download',
    ReplaySearch = 'replaySearch',
    SendFeedback = 'sendFeedback',
    SpotIQAnalyze = 'spotIQAnalyze',
    CopyAndEdit = 'context-menu-item-copy-and-edit',
    TML = 'tml',
    ExportTML = 'exportTSL',
    EditTML = 'editTSL',
    UpdateTML = 'updateTSL',
    QueryDetailsButtons = 'queryDetailsButtons',
    CreateMonitor = 'createMonitor',
    CopyLink = 'embedDocument',
    ManageMonitor = 'manageMonitor',
    EnableContextualChangeAnalysis = 'enableContextualChangeAnalysis',
    EnableIterativeChangeAnalysis = 'enableIterativeChangeAnalysis',
    AnswerChartSwitcher = 'answerChartSwitcher',
    ToggleSize = 'toggleSize',
    Explore = 'explore',
    Present = 'present',
    Annotate = 'annotate',
    AddToFavorites = 'addToFavorites',
    LiveboardDetails = 'liveboardDetails',
    Schedule = 'subscription',
    MakeACopy = 'makeACopy',
    SchedulesList = 'schedule-list',
    LiveboardInfo = 'pinboardInfo',
    Remove = 'delete',
    AddFilter = 'addFilter',
    LiveboardUsers = 'liveboardUsers', // this is not defined in the module
    ConfigureFilter = 'configureFilter',
    ReportError = 'reportError',
    EditTitle = 'editTitle',
    EditDetails = 'editDetails',
    TileSizeSelect = 'sizeSelect',
    EditTitleDescription = 'editTitleDescription', // todo: check usage
    RenameModalTitleDescription = 'renameModalTitleDescription',
    ManageSchedules = 'manageSchedules',
    RequestAccess = 'requestAccess',
    SyncToSheets = 'sync-to-sheets',
    SyncToOtherApps = 'sync-to-other-apps',
    ManagePipelines = 'manage-pipeline',
    SyncToSlack = 'syncToSlack',
    SyncToTeams = 'syncToTeams',
    CollapseDataSources = 'collapseDataSources',
    CollapseDataPanel = 'collapseDataPanel',
    AddFormula = 'addFormula',
    AddParameter = 'addParameter',
    AddSimpleCohort = 'addSimpleCohort',
    AddAdvancedCohort = 'addAdvancedCohort',
    AddDataPanelObjects = 'addDataPanelObjects',
    ChooseDataSources = 'chooseDataSources',
    AxisMenuAggregate = 'axisMenuAggregate',
    AxisMenuTimeBucket = 'axisMenuTimeBucket',
    AxisMenuFilter = 'axisMenuFilter',
    AxisMenuConditionalFormat = 'axisMenuConditionalFormat',
    AxisMenuSort = 'axisMenuSort',
    AxisMenuGroup = 'axisMenuGroup',
    AxisMenuPosition = 'axisMenuPosition',
    AxisMenuRename = 'axisMenuRename',
    AxisMenuEdit = 'axisMenuEdit',
    AxisMenuNumberFormat = 'axisMenuNumberFormat',
    AxisMenuTextWrapping = 'axisMenuTextWrapping',
    AxisMenuRemove = 'axisMenuRemove',
    InsertInToSlide = 'insertInToSlide',
    RequestVerification = 'requestVerification',
    MarkAsVerified = 'markAsVerified',
    AddTab = 'addTab',
    PersonalisedViewsDropdown = 'personalisedViewsDropdown',
    ShowSageQuery = 'showSageQuery',
    EditSageAnswer = 'editSageAnswer',
    SageAnswerFeedback = 'sageAnswerFeedback',
    ModifySageAnswer = 'modifySageAnswer',
    ShowVersionHistory = 'showVersionHistory',
    EnableVersionControl = 'enableVersionControl',
    DisableVersionControl = 'disableVersionControl',
    WithdrawVerification = 'withdrawVerification',
    AskAi = 'AskAi',
    VerifiedLiveboard = 'verifiedLiveboard',
    AIHighlights = 'AIHighlights',
    OrganiseFavourites = 'organiseFavourites',
    AddToWatchlist = 'addToWatchlist',
    ManageTags = 'manageTags',
    RemoveFromWatchlist = 'removeFromWatchlist',
    ViewLiveboard = 'viewLiveboard',
    CopyKpiLink = 'copyKpiLink',
    CreateLiveboard = 'createLiveboard',
    RenameLiveboard = 'renameLiveboard',
    FilterPanelToggle = 'filterPanelToggle',
    EditScheduleHomepage = 'editScheduleHomepage',
    PauseScheduleHomepage = 'pauseScheduleHomepage',
    ViewScheduleRunHomepage = 'viewScheduleRunHomepage',
    UnsubscribeScheduleHomepage = 'unsubscribeScheduleHomepage',
    DeleteScheduleHomepage = 'deleteScheduleHomepage',
    KPIAnalysisCTA = 'kpiAnalysisCTA',
}

export enum DispatchAction {
    CrossFilterModified = 'Cross filter added/updated',
    CrossFilterRemoved = 'Cross filter removed',
    AllCrossFilterRemoved = 'All the cross filters removed',
}

export enum EmbedUrlsEnum {
    PINBOARD_URL = 'PINBOARD_URL',
    PINBOARD_VIZ_URL = 'PINBOARD_VIZ_URL',
    ANSWER_URL = 'ANSWER_URL',
    INSIGHT_URL = 'INSIGHT_URL',
    UNSUBSCRIBE_URL = 'UNSUBSCRIBE_URL',
}

interface IncomingRuntimeFilter {
    columnName: string;
    operator: string;
    values: (number | boolean | string)[];
}

export interface IncomingRuntimeParameter {
    name: string;
    value: number | boolean | string;
}

const runtimeFilterParams: EmbedParams = {};
const runtimeParameterParams: EmbedParams = {};

export interface ActionState {
    isHidden: boolean;
    isDisabled: boolean;
}

export enum ActionSelect {
    None = 'NONE',
    Basic = 'BASIC',
    Bearer = 'BEARER',
    Apikey = 'APIKEY',
}

export const errorTypeToErrorMap: {
    [key in eventErrorType]?: string;
} = {
    [eventErrorType.Fullscreen]:
        'Failed to enable fullscreen, This may be happening because there was no user interaction with embedded object',
    [eventErrorType.SingleValueFilter]:
        'Filter {filter_name}: Cannot pass multiple filtering elements to this single value filter.',
    [eventErrorType.NonExistFilter]:
        'UpdateFilters could not update the filter on {filter_name} as it is not an existing filter in the liveboard. Please edit the liveboard and add {filter_name} as a filter chip in order to update it programatically.',
    [eventErrorType.InvalidDateValue]:
        'UpdateFilters could not update the filter on {filter_name} as invalid date value provided.',
    [eventErrorType.NoActivePersonalisedViewsError]:
        'No Personalized Liveboard view is selected.',
    [eventErrorType.InvalidOperator]:
        'UpdateFilters could not update the filter on {filter_name} as invalid operator value provided.',
    [eventErrorType.WrongVizId]:
        'UpdateCrossFilters could not update the filter as invalid VizId value provided.',
    [eventErrorType.NotAWorksheet]:
        'UpdateCrossFilters could not update the filter as VizId value provided does not consist any worksheet.',
    [eventErrorType.NoFilterableColumnPresent]:
        'Could not apply CrossFilter may be because of the following reason: ColumnName does not match to any Column present in the visualization || values are not provided',
    [eventErrorType.filterExistsOrNoAttributeColumn]:
        'UpdateCrossFilters could not update the filter as column is not an attribute or a filter is already existing on this column',
};
interface RouteChangeData {
    currentPath: string;
}
let hostConfig: HostConfig;

export const setHostConfig = (_hostConfig: HostConfig) => {
    hostConfig = _hostConfig;
};

export const getHostConfig = (): HostConfig => {
    return hostConfig;
};

let hiddenHomeLeftNavItems: HomeLeftNavItems[] = [];

export const setHiddenHomeLeftNavItems = (
    _hiddenHomeLeftNavItems: HomeLeftNavItems[],
) => {
    hiddenHomeLeftNavItems = _hiddenHomeLeftNavItems;
};

export const getHiddenHomeLeftNavItems = (): HomeLeftNavItems[] => {
    return hiddenHomeLeftNavItems;
};

let embedCssParams: Record<string, string | number> = {};

export const setEmbedCssParams = (
    _embedCssParams: Record<string, string | number>,
) => {
    embedCssParams = _embedCssParams;
};

export const getEmbedCssParams = (variable: string) => {
    return embedCssParams?.[variable];
};

let hiddenHomepageModules: HomepageModule[] = [];

export const setHiddenHomepageModules = (
    _hiddenHomepageModules: HomepageModule[],
) => {
    hiddenHomepageModules = _hiddenHomepageModules;
};

export const getHiddenHomepageModules = (): HomepageModule[] => {
    return hiddenHomepageModules;
};

let reorderedHomepageModules: HomepageModule[] = [];

export const setReorderedHomepageModules = (
    _reorderedHomepageModules: HomepageModule[],
) => {
    reorderedHomepageModules = _reorderedHomepageModules;
};

export const getReorderedHomepageModules = (): HomepageModule[] => {
    return reorderedHomepageModules;
};

export interface RouteChangeDataEvent {
    type: string;
    data: RouteChangeData;
}

export const JsonStringtoArrayRequestHeaders = (urlHeaders: string) => {
    return Object.values(JSON.parse(urlHeaders));
};

export const urlAuthHeadersForCutomActions = (actionInfo: any) => {
    let authHeader = {};
    switch (actionInfo.authSelect) {
        case ActionSelect.Basic:
            authHeader = {
                Authorization: `Basic ${actionInfo.encodeUser}`,
            };
            break;
        case ActionSelect.Apikey:
            authHeader = { [actionInfo.apiKey]: actionInfo.apiValue };
            break;
        case ActionSelect.Bearer:
            authHeader = {
                Authorization: `Bearer ${actionInfo.authToken}`,
            };
            break;
        default:
            authHeader = {};
    }
    return authHeader;
};

const ActionItemMap = {
    showUnderlyingData: ['context-menu-item-show-underlying-data'],
    spotIQAnalyze: ['context-menu-item-spotiq-analyze'],
};

export const isSearchEmbed = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_SEARCH_EMBED] || false;
};

export const isSageEmbed = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_SAGE_EMBED] || false;
};

export const hideSageAnswerHeader = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[HIDE_SAGE_ANSWER_HEADER] || false;
};

export const hideSearchBarTitle = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[HIDE_SEARCH_BAR_TITLE] || false;
};

export const hideWorksheetSelector = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_SEARCH_WORKSHEET_SELECTOR_HIDDEN] || false;
};

export const disableWorksheetChange = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[DISABLE_WORKSHEET_CHANGE] || false;
};

export const isProductTour = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_PRODUCT_TOUR] || false;
};

export const hideEurekaSuggestions = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[HIDE_EUREKA_SUGGESTIONS] || false;
};

export const isSearchBarHidden = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_SEARCH_BAR_HIDDEN] ?? false;
};

export const isLiveboardEmbed = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_LIVEBOARD_EMBED] || false;
};

export const isFullAppEmbed = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_FULL_APP_EMBED] || false;
};

export const isLBHeaderHiddenInEmbed = (embedConfig: EmbedContextOptions) => {
    if (!embedConfig.isEmbedded) return false;
    const queryParams = getEmbedQueryParams();
    return queryParams?.hideLiveboardHeader || false;
};

export const isOnBeforeGetVizDataInterceptEnabled = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams?.isOnBeforeGetVizDataInterceptEnabled || false;
};

export const isFilterPanelHiddenInEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    if (!embedConfig?.isEmbedded) return false;
    const queryParams = getEmbedQueryParams();
    return queryParams?.hideFilterPanel || false;
};

export const shouldShowLBTitleInEmbed = (embedConfig: EmbedContextOptions) => {
    if (!embedConfig.isEmbedded) return true;
    const queryParams = getEmbedQueryParams();
    if (_.isUndefined(queryParams?.showLiveboardTitle)) {
        return !isLiveboardEmbed();
    }
    return queryParams?.showLiveboardTitle;
};

export const shouldShowLiveboardVerifiedBadge = (
    embedConfig: EmbedContextOptions,
) => {
    if (!embedConfig.isEmbedded) return true;
    const queryParams = getEmbedQueryParams();
    return queryParams?.showLiveboardVerifiedBadge ?? true;
};

export const shouldShowLiveboardReverifyBanner = (
    embedConfig: EmbedContextOptions,
) => {
    if (!embedConfig.isEmbedded) return true;
    const queryParams = getEmbedQueryParams();
    return queryParams?.showLiveboardReverifyBanner ?? true;
};

export const shouldShowLBDescriptionInEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    if (!embedConfig.isEmbedded) return true;
    const queryParams = getEmbedQueryParams();
    if (_.isUndefined(queryParams?.showLiveboardDescription)) {
        return !isLiveboardEmbed();
    }
    return queryParams?.showLiveboardDescription;
};

export const linkOverrideEnabled = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[IS_LINKOVERRIDE_ENABLED] || false;
};

export const isVizEmbed = () => {
    const path = window.location.href.indexOf(IS_PINBOARD_VIZ_EMBED);
    if (path > -1) {
        const params = window.location.href
            .substring(path + IS_PINBOARD_VIZ_EMBED.length + 1)
            .split('/');
        return params.length === 2 && !!params[1].length;
    }
    return false;
};

export const getAdditionalPendoKey = () => {
    const queryParams = getEmbedQueryParams();
    return queryParams[ADDITIONAL_PENDO_KEY] || '';
};

export const addActionItemMapFromUrlParams = (actionIds: Set<string>) => {
    const modifiedActionIds: Set<string> = actionIds;
    Object.keys(ActionItemMap).forEach(key => {
        if (actionIds.has(key)) {
            ActionItemMap[key].forEach((e: string) => modifiedActionIds.add(e));
        }
    });
    return modifiedActionIds;
};

export const getDisabledActions = (embedConfig: EmbedContextOptions) => {
    const { embedParams, isEmbedded } = embedConfig;
    if (!isEmbedded) {
        return new Set<string>();
    }
    const disableActionIds = addActionItemMapFromUrlParams(
        new Set<string>(embedParams.disableAction),
    );
    return disableActionIds;
};

export const getDisabledActionReason = (embedConfig: EmbedContextOptions) => {
    const { embedParams } = embedConfig;
    return embedParams?.disableHint;
};

export const getHiddenActions = (embedConfig: EmbedContextOptions) => {
    const { embedParams, isEmbedded } = embedConfig;
    if (!isEmbedded) {
        return new Set<string>();
    }
    const hiddenActionIds = addActionItemMapFromUrlParams(
        new Set<string>(embedParams.hideAction),
    );
    return hiddenActionIds;
};

export const sendIframeUrlToParent = async () => {
    const iframeUrl = window.location.href;
    return iframeUrl
  };

export const getVisibleActions = (embedConfig: EmbedContextOptions) => {
    const { embedParams, isEmbedded } = embedConfig;
    if (!isEmbedded) {
        return undefined;
    }
    if (embedParams.visibleAction) {
        const visibleActionsIds = addActionItemMapFromUrlParams(
            new Set<string>(embedParams.visibleAction),
        );
        return visibleActionsIds;
    }
    return undefined;
};

export interface EmbedAnswerData {
    answerSessionId: string;
    vizId?: string;
    getAnswerPageConfig: () => AnswerPageConfigInterface;
    useAppContext: () => BlinkContextOptions;
    getCachedAnswerForHasUnsavedChanges: any;
    hasUnsavedChanges: any;
    useGetAnswerColumnsAndDataQuery: any;
    getAnswerHeaderMetadataFromCache: any;
    client: ApolloClient<object>;
}

export const useEmbedAnswerData = ({
    answerSessionId,
    vizId,
    getAnswerPageConfig,
    useAppContext,
    getCachedAnswerForHasUnsavedChanges,
    hasUnsavedChanges,
    useGetAnswerColumnsAndDataQuery,
    getAnswerHeaderMetadataFromCache,
    client,
}: EmbedAnswerData) => {
    const { sessionService } = useAppContext();
    const { referenceSavedState } = getAnswerPageConfig();
    const headerMetadata = getAnswerHeaderMetadataFromCache(
        answerSessionId,
        client,
    );
    const columnsAndData = answerSessionId
        ? useGetAnswerColumnsAndDataQuery(
              answerSessionId,
              headerMetadata?.displayMode,
          )
        : {};
    const { getUserEmail, getUserGuid, getUserName }: any = sessionService;
    const userInfo = {
        userName: getUserName(),
        userGUID: getUserGuid(),
        userEmail: getUserEmail(),
    };

    const reportBookMetadata = {
        id: referenceSavedState?.id,
        description: referenceSavedState?.description,
        metadata: referenceSavedState?.metadata,
        name: referenceSavedState?.name,
        suggestedDisplayMode: referenceSavedState?.suggestedDisplayMode,
        visualizations: referenceSavedState?.visualizations,
        headerMetadata,
    };
    const isAnswerUnsaved = hasUnsavedChanges;
    const vizIdObj = vizId ? { vizId } : {};
    return {
        ...columnsAndData,
        user: userInfo,
        reportBookMetadata,
        isAnswerUnsaved,
        ...vizIdObj,
    };
};

export const getHookInEmbedMode = <T>(embedHook: T, noOpMethod = () => ({})) =>
    isEmbedCheck() ? embedHook : noOpMethod;

/**
 * returns if an action should be shown using URL flags visibleActionIds and hiddenActionIds or when Liveboard is embedded
 */
export const shouldShowAction = (
    visibleActionIds: Set<string>,
    hiddenActionIds: Set<string>,
    actionId: string,
    isPinboardAction = false,
) => {
    if (
        isLiveboardEmbed() &&
        actionId === Action.MakeACopy &&
        !isPinboardAction
    )
        return false;
    if (visibleActionIds) {
        return visibleActionIds?.has(actionId);
    }
    if (hiddenActionIds?.has(actionId)) {
        return false;
    }
    return true;
};

/**
 * returns if any action should be hidden using URL flags hideAction and visibleAction or when Liveboard is embedded
 */

/**
 * returns a set of visible Tab ids defined using URL flag visibleTabs
 */
export const getPinboardVisibleTabsForEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    const { isEmbedded, embedParams } = embedConfig;
    if (isEmbedded && embedParams.visibleTabs) {
        return new Set<string>(embedParams.visibleTabs);
    }
    return undefined;
};

/**
 * Set array of visible Tab ids
 */
export const setPinboardVisibleTabsForEmbed = (
    embedConfig: EmbedContextOptions,
    visibleTabIds: string[],
) => {
    if (!embedConfig?.isEmbedded || !visibleTabIds) {
        return;
    }
    embedConfig.updateEmbedParams((prevParams: EmbedParams) => {
        return {
            ...prevParams,
            visibleTabs: visibleTabIds,
            hideTabs: undefined as string[],
        };
    });
};

/**
 * returns a set of hidden Tab ids defined using URL flag hideTabs
 */
export const getPinboardHiddenTabsForEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    const { isEmbedded, embedParams } = embedConfig;
    if (isEmbedded && embedParams.hideTabs) {
        return new Set<string>(embedParams.hideTabs);
    }
    return undefined;
};

/**
 * Set array of hidden Tab ids
 */
export const setPinboardHiddenTabsForEmbed = (
    embedConfig: EmbedContextOptions,
    hiddenTabIds: string[],
) => {
    if (!embedConfig?.isEmbedded || !hiddenTabIds) {
        return;
    }
    embedConfig.updateEmbedParams((prevParams: EmbedParams) => {
        return {
            ...prevParams,
            hideTabs: hiddenTabIds,
            visibleTabs: undefined as string[],
        };
    });
};

/**
 * returns if any tab should be hidden using URL flags hideTabs and visibleTabs
 */
export const shouldHideTabForEmbed = (
    embedConfig: EmbedContextOptions,
    tabID: string,
) => {
    if (!embedConfig?.isEmbedded) return false;
    const hiddenTabsForEmbed = getPinboardHiddenTabsForEmbed(embedConfig);
    const visibleTabsForEmbed = getPinboardVisibleTabsForEmbed(embedConfig);

    if (hiddenTabsForEmbed) {
        return hiddenTabsForEmbed?.has(tabID);
    }
    if (visibleTabsForEmbed) {
        return !visibleTabsForEmbed?.has(tabID);
    }
    return false;
};


        
const isHiddenForEmbed = (
    embedConfig: EmbedContextOptions,
    actionType: Action | string,
    isPinboardAction = false,
    hiddenActions?: Set<string>,
    visibleActions?: Set<string>,
): boolean => {
    if (isLiveboardEmbed() && actionType === Action.Remove) {
        return true;
    }
    if (isVizEmbed() && actionType === Action.Explore) {
        return true;
    }
    if (embedConfig.isEmbedded && actionType === Action.Annotate) {
        return true;
    }
    const hiddenActionsForEmbed =
        hiddenActions || getHiddenActions(embedConfig);
    const visibleActionsForEmbed =
        visibleActions || getVisibleActions(embedConfig);
    return (
        embedConfig.isEmbedded &&
        !shouldShowAction(
            visibleActionsForEmbed,
            hiddenActionsForEmbed,
            actionType,
            isPinboardAction,
        )
    );
};





/**
 * returns map which contains info if action is disabled or hidden in embed
 */
const embedActionsMapForParamsMap: Map<
    string,
    Map<Action, ActionState>
> = new Map();
export const getEmbedActionStateMap = (
    embedConfig: EmbedContextOptions,
): Map<Action, ActionState> => {
    const embedParamsStr = JSON.stringify(embedConfig.embedParams);
    if (!embedActionsMapForParamsMap?.has(embedParamsStr)) {
        const newEmbedActionStateMap = new Map<Action, ActionState>();
        const disabledActions = getDisabledActions(embedConfig);
        const hiddenActions = getHiddenActions(embedConfig);
        const visibleActions = getVisibleActions(embedConfig);

        Object.values(Action).forEach(action => {
            const state: ActionState = {
                isHidden: isHiddenForEmbed(
                    embedConfig,
                    action,
                    false,
                    hiddenActions,
                    visibleActions,
                ),
                isDisabled: disabledActions?.has(action),
            };
            newEmbedActionStateMap.set(action, state);
        });
        embedActionsMapForParamsMap.set(embedParamsStr, newEmbedActionStateMap);
    }
    return embedActionsMapForParamsMap.get(embedParamsStr);
};

/**
 * returns if any action should be hidden using URL flags hideAction and visibleAction or when Liveboard is embedded
 */
export const shouldHideForEmbed = (
    embedConfig: EmbedContextOptions,
    actionType: Action | string,
    isPinboardAction = false,
): boolean => {

    if(isPinboardAction || !Object.values(Action).includes(actionType as Action)){
        return isHiddenForEmbed(embedConfig,
            actionType,
            isPinboardAction,
        );        
    }

    const embedParamsStr = JSON.stringify(embedConfig.embedParams);
    if(embedActionsMapForParamsMap?.get(embedParamsStr)?.get(actionType as Action) === undefined){
        return getEmbedActionStateMap(embedConfig).get(actionType as Action).isHidden;
    }
    return embedActionsMapForParamsMap.get(embedParamsStr).get(actionType as Action).isHidden;
};


/**
 * returns if any action should be disabled using URL flag disableAction
 */
export const shouldDisableForEmbed = (
    embedConfig: EmbedContextOptions,
    actionType: Action | string,
): boolean => {
    if(!Object.values(Action).includes(actionType as Action)) return false; 
    const embedParamsStr = JSON.stringify(embedConfig.embedParams);
    if(embedActionsMapForParamsMap?.get(embedParamsStr)?.get(actionType as Action) === undefined){
        return getEmbedActionStateMap(embedConfig).get(actionType as Action).isDisabled;
    }
    return embedActionsMapForParamsMap.get(embedParamsStr).get(actionType as Action).isDisabled;
};



/**
 * returns if cross button should be hidden on filter chip using URL flag preventPinboardFilterRemoval
 */
export const getFilterRemovalPermissionFromUrlParam = (
    embedConfig: EmbedContextOptions = {} as EmbedContextOptions,
) => {
    const { embedParams } = embedConfig;
    return !embedParams?.preventPinboardFilterRemoval;
};

/**
 * returns a set of visible viz ids defined using URL flag pinboardVisibleVizs
 */
export const getPinboardVisibleVizsForEmbed = (
    embedConfig: EmbedContextOptions,
) => {
    const { isEmbedded, embedParams } = embedConfig;
    if (!isEmbedded) {
        return undefined;
    }
    if (embedParams.pinboardVisibleVizs) {
        return new Set<string>(embedParams.pinboardVisibleVizs);
    }
    return undefined;
};

const parseQueryParams = (queryString: string, paramsObject: EmbedParams) => {
    const parsedParams: EmbedParams = {};

    const queryParams = new URLSearchParams(queryString);
    queryParams.forEach((value, key) => {
        if (_.isUndefined(parsedParams[key])) {
            parsedParams[key] = [];
        }
        parsedParams[key].push(value);
    });

    Object.keys(parsedParams).forEach(key => {
        const value = parsedParams[key];
        if (value.length === 1) {
            parsedParams[key] = value[0];
        }
    });

    Object.assign(paramsObject, parsedParams);
};

export const setRuntimeFilterParams = (data: string) => {
    parseQueryParams(data, runtimeFilterParams);
};

export const setRuntimeParameterParams = (data: string) => {
    parseQueryParams(data, runtimeParameterParams);
};

export const getRuntimeFilterParams = (): EmbedParams => {
    return runtimeFilterParams;
};

export const getRuntimeParameterParams = (): EmbedParams => {
    return runtimeParameterParams;
};

/**
 * Apply runtime params overrides to the existing url params.
 * @param pbParametersOverrides update overrides
 * @returns updated url params
 */
export const updateParametersWithOverrides = (
    urlParams: { [key: string]: string },
    pbParametersOverrides: IncomingRuntimeParameter[],
) => {
    // Create a map to store the parameter overrides
    const pbParametersOverridesMap = new Map<
        string,
        IncomingRuntimeParameter
    >();
    for (let i = 0; i < pbParametersOverrides.length; i++) {
        const pbParametersOverride = pbParametersOverrides[i];
        pbParametersOverridesMap.set(
            pbParametersOverride.name,
            pbParametersOverride,
        );
    }

    // Make a copy of urlParams to avoid mutating the original
    const urlParamsCopy = { ...urlParams };
    const pbParametersCurrentMap = new Map<string, IncomingRuntimeParameter>();

    // Iterate over the urlParams to gather existing parameters
    const urlKeys = Object.keys(urlParams);
    for (let i = 0; i < urlKeys.length; i++) {
        const key = urlKeys[i];
        const runtimeParametersCheck = key.match(/^param(\d+)/);

        if (
            Object.prototype.hasOwnProperty.call(urlParams, key) &&
            runtimeParametersCheck
        ) {
            const index = (runtimeParametersCheck[1] as unknown) as number;
            const paramName = urlParams[key];
            const paramValue = urlParams[`paramVal${index}`];

            if (pbParametersOverridesMap.has(key)) {
                // If there's an override, replace the existing param
                const overrideParam = pbParametersOverridesMap.get(key);
                urlParamsCopy[key] = overrideParam.value.toString();
                pbParametersCurrentMap.set(paramName, overrideParam);
            } else {
                pbParametersCurrentMap.set(paramName, {
                    name: paramName,
                    value: paramValue,
                });
            }

            delete urlParamsCopy[key];
            delete urlParamsCopy[`paramVal${index}`];
        }
    }

    const pbParametersMerged = new Map<string, IncomingRuntimeParameter>([
        ...pbParametersCurrentMap,
        ...pbParametersOverridesMap,
    ]);

    let parametersIndex = 1;
    pbParametersMerged.forEach(
        (pbParameterMerged: IncomingRuntimeParameter) => {
            urlParamsCopy[`param${parametersIndex}`] = pbParameterMerged.name;
            urlParamsCopy[
                `paramVal${parametersIndex}`
            ] = pbParameterMerged.value.toString();
            parametersIndex++;
        },
    );

    return urlParamsCopy;
};

/**
 * Apply runtime filter overrides to the existing url params.
 * @param urlParams Existing url params
 * @param pbFilterOverrides update overrides
 * @returns Updared url params
 */
export const updateRuntimeFiltersWithOverrides = (
    urlParams: { [key: string]: string },
    pbFilterOverrides: IncomingRuntimeFilter[],
) => {
    // Put into a map ds
    // Storing incoming runtime filters in pbFilterOveridesMap
    const pbFilterOverridesMap = new Map<string, IncomingRuntimeFilter[]>();
    pbFilterOverrides.forEach(f => {
        if (!pbFilterOverridesMap.has(f.columnName)) {
            pbFilterOverridesMap.set(f.columnName, []);
        }
        pbFilterOverridesMap.get(f.columnName).push(f);
    });

    const pbFilterCurrentMap = new Map();
    const urlParamsCopy = { ...urlParams };
    Object.keys(urlParams).forEach((key: string) => {
        const runtimeFilterCheck = key.match(/^col(\d+)/);
        if (!runtimeFilterCheck) {
            return;
        }
        const idx = (runtimeFilterCheck[1] as unknown) as number;
        if (!pbFilterCurrentMap.has(urlParams[key])) {
            pbFilterCurrentMap.set(urlParams[key], []);
        }
        pbFilterCurrentMap.get(urlParams[key]).push({
            columnName: urlParams[key],
            operator: urlParams[`op${idx}`],
            values: urlParams[`val${idx}`],
        });
        delete urlParamsCopy[key];
        delete urlParamsCopy[`op${idx}`];
        delete urlParamsCopy[`val${idx}`];
    });

    // Merge existing and override maps on column name keys such that
    // existing filters on now overriden columns are completely removed (all operations)
    // existing filters on non-overridden columns stay
    // new filters(on non-existing columns) also apply
    const pbFilterMerged = new Map([
        ...pbFilterCurrentMap,
        ...pbFilterOverridesMap,
    ]);
    let filterIdx = 1;
    pbFilterMerged.forEach(filters => {
        filters.forEach((f: IncomingRuntimeFilter) => {
            urlParamsCopy[`col${filterIdx}`] = f.columnName;
            urlParamsCopy[`op${filterIdx}`] = f.operator;
            urlParamsCopy[`val${filterIdx}`] =
                f.values.length === 1 ? f.values[0] : (f.values as any);
            filterIdx++;
        });
    });
    return urlParamsCopy;
};

/**
 * Use this handler for listening and handling events at root level
 *
 * @param embedConfig Embed context config
 */
export const useEmbedEventsRootHandler = ({
    useEmbedEventSubscription,
    updateEmbedParams,
    updateRuntimeFilterParams,
    runtimeFilterParams,
    updateEmbedParameterParams,
    embedParameterParams,
}: EmbedContextOptions) => {
    useEmbedEventSubscription(
        IncomingEventType.UpdateParameters,
        (updatedParams: IncomingRuntimeParameter[]) => {
            if (!!updatedParams && !!updatedParams.length) {
                if (!_.isEmpty(embedParameterParams)) {
                    updateEmbedParameterParams((prevParams: EmbedParams) => {
                        return updateParametersWithOverrides(
                            prevParams,
                            updatedParams,
                        );
                    });
                } else {
                    updateEmbedParams((prevParams: EmbedParams) => {
                        return updateParametersWithOverrides(
                            prevParams,
                            updatedParams,
                        );
                    });
                }
            }
        },
    );

    useEmbedEventSubscription(
        IncomingEventType.UpdateRuntimeFilters,
        (updatedFilters: IncomingRuntimeFilter[]) => {
            if (!!updatedFilters && !!updatedFilters.length) {
                if (!_.isEmpty(runtimeFilterParams)) {
                    updateRuntimeFilterParams((prevParams: EmbedParams) => {
                        return updateRuntimeFiltersWithOverrides(
                            prevParams,
                            updatedFilters,
                        );
                    });
                } else {
                    updateEmbedParams((prevParams: EmbedParams) => {
                        return updateRuntimeFiltersWithOverrides(
                            prevParams,
                            updatedFilters,
                        );
                    });
                }
            }
        },
    );

    useEmbedEventSubscription(
        IncomingEventType.Navigate,
        (data: { path: any; replace: boolean } | string | number) => {
            let path = data;
            let replace = false;
            if (typeof data === 'object') {
                path = data?.path;
                replace = !!data?.replace;
            }
            if (typeof path === 'number') {
                window.history.go(path);
            }
            // Not using navService to avoid the parent app's history
            // from being updated.
            if (replace) {
                window.history.replaceState(null, '', `#/${path}`);
            } else {
                window.location.hash = `#/${path}`;
            }
        },
    );
    useEmbedEventSubscription(
        IncomingEventType.GetIframeUrl,
        sendIframeUrlToParent
    );
};

const isRuntimeFilterParam = (val: string, key: string) =>
    key.match(/^(col|op|val)(\d+)/);

export const isRuntimeParametersParam = (val: string, key: string) =>
    key.match(/^(param|paramVal)(\d+)/);

export const useEmbedParamsChanged = (
    isRuntimeParam: boolean,
    callback: () => void,
) => {
    const {
        embedParams,
        runtimeFilterParams,
        embedParameterParams,
        dispatchEmbedEvent,
    } = useEmbedContext();
    const prevParams = useRef<any>(null);

    useEffect(() => {
        if (prevParams.current) {
            const prevEmbedParams = _.pickBy(
                prevParams.current,
                isRuntimeParam
                    ? isRuntimeParametersParam
                    : isRuntimeFilterParam,
            );
            const currentEmbedParams = _.pickBy(
                embedParams,
                isRuntimeParam
                    ? isRuntimeParametersParam
                    : isRuntimeFilterParam,
            );

            if (!_.isEqual(prevEmbedParams, currentEmbedParams)) {
                callback();
            }
        }

        prevParams.current = isRuntimeParam
            ? embedParameterParams || embedParams
            : runtimeFilterParams || embedParams;
    }, [embedParams, runtimeFilterParams, embedParameterParams]);
};
/**
 * Listener for runtimeFilters in embed context change
 *
 * @param callback Callback to be called
 */
export const useEmbedRuntimeFiltersChanged = (callback: () => void) => {
    useEmbedParamsChanged(false, callback);
};

/**
 * Listener for runtimeParameters in embed context change
 *
 * @param callback Callback to be called
 */
export const useEmbedRuntimeParametersChanged = (callback: () => void) => {
    useEmbedParamsChanged(true, callback);
};

const FOOTER_HEIGHT_V1 = 16; // Height of footer in V1. This is mostly a fixed value,
// so using this directly instead of recalculating.
const BUFFER_HEIGHT = 30; // To avoid unneccesary re-sizes.
const MIN_DIFF = 40; // To avoid continous re-sizing,
// This Value is choose as Tab height is 44px which is least when we want to dispatch embed height

/**
 * Use the layout change notifier, which can be used to re-size the viewport frame for removing additonal scrollbar.
 * @returns { updateHeight }
 */
export const useLayoutChangeNotifier = (
    dispatchEmbedEvent: Dispatcher,
    footerExcluded: boolean,
    isFooterHidden: boolean,
) => {
    // Maintain the last determined height so that we don't
    // communicate the same twice in a row to the parent
    const currentHeight = useRef(null);

    // When embed container is wrapped in blinkV1, footer is used from v1.
    const FOOTER_CORRECTION =
        footerExcluded && !isFooterHidden ? FOOTER_HEIGHT_V1 : 0;

    return {
        updateHeight: (height: number) => {
            const height_diff = Math.abs(currentHeight.current - height);
            if (height_diff > MIN_DIFF) {
                currentHeight.current =
                    height + FOOTER_CORRECTION + BUFFER_HEIGHT;
                dispatchEmbedEvent(
                    EventType.EMBED_HEIGHT,
                    currentHeight.current,
                );
            }
        },
    };
};

const answerIdToVizGUIDMap = new Map<string, string>();

export const getVizGUID = (answerId: string): string | undefined => {
    return answerIdToVizGUIDMap.get(answerId);
};

export const setAnswerIdToVizGUIDMap = (answerId: string, vizGUID: string) => {
    answerIdToVizGUIDMap.set(answerId, vizGUID);
};

interface CustomCSS {
    variables: {
        [key: string]: string;
    };
    rules_UNSTABLE: {
        [className: string]: {
            [field: string]: string;
        };
    };
}

const propertyTemplate = (property: string, value: string) =>
    `${property}: ${value};`;
const ruleTemplate = (ruleName: string, styles: string) => `${ruleName} {
    ${styles}
}`;

export const convertCustomCSSObjectToStyle = (customCSS: CustomCSS) => {
    const { variables = {}, rules_UNSTABLE: rulesUnstable = {} } = customCSS;
    const variableStyles = _.keys(variables).map(variable =>
        propertyTemplate(variable, variables[variable]),
    );
    const variableCss = ruleTemplate(':root', variableStyles.join('\n')); // Double selector for more specificity
    const rules = _.keys(rulesUnstable)
        .map(ruleName =>
            ruleTemplate(
                ruleName,
                _.keys(rulesUnstable[ruleName])
                    .map(field =>
                        propertyTemplate(field, rulesUnstable[ruleName][field]),
                    )
                    .join('\n'),
            ),
        )
        .join('\n');
    return `
        ${variableCss}
        ${rules}
    `;
};

export const CUSTOM_CSS_URL_ELEMENT_ID = 'embedCustomCssUrl';
export const CUSTOM_CSS_ELEMENT_ID = 'embedCustomCss';
export const addCustomCssElements = (data: any): void => {
    const customizations = data?.customizations || data?.customisations;
    if (customizations?.style?.customCSSUrl) {
        let link: any;
        if (!document.getElementById(CUSTOM_CSS_URL_ELEMENT_ID)) {
            link = document.createElement('link');
            link.setAttribute('id', CUSTOM_CSS_URL_ELEMENT_ID);
            link.setAttribute('rel', 'stylesheet');
            link.setAttribute('type', 'text/css');
            document.body.appendChild(link);
        } else {
            link = document.getElementById(CUSTOM_CSS_URL_ELEMENT_ID);
        }
        link.href = customizations?.style?.customCSSUrl;
    }
    if (customizations?.style?.customCSS) {
        setEmbedCssParams(customizations.style.customCSS?.variables);
        if (document.getElementById(CUSTOM_CSS_ELEMENT_ID)) {
            document.getElementById(CUSTOM_CSS_ELEMENT_ID).remove();
        }
        const customCssEl = document.createElement('style');
        customCssEl.setAttribute('id', CUSTOM_CSS_ELEMENT_ID);
        customCssEl.setAttribute('type', 'text/css');
        // eslint-disable-next-line no-unsanitized/property
        customCssEl.innerHTML = convertCustomCSSObjectToStyle(
            customizations?.style?.customCSS,
        );
        document.body.appendChild(customCssEl);
    }
    return null;
};
export const setEmbedInitData = async (data: any) => {
    const hiddenHomeLeftNavItems = data?.hiddenHomeLeftNavItems;
    const runtimeFilterParams = data?.runtimeFilterParams;
    const runtimeParameterParams = data?.runtimeParameterParams;
    const hostConfig = data?.hostConfig;
    const authToken = data?.authToken;
    const hiddenHomepageModules = data?.hiddenHomepageModules;
    const reorderedHomepageModules = data?.reorderedHomepageModules;
    setHiddenHomeLeftNavItems(hiddenHomeLeftNavItems);
    addCustomCssElements(data);
    if (runtimeFilterParams) {
        setRuntimeFilterParams(runtimeFilterParams);
    }
    if (runtimeParameterParams) {
        setRuntimeParameterParams(runtimeParameterParams);
    }
    setHostConfig(hostConfig);
    if (authToken) {
        overrideBrowserFetchAndXhrForToken(authToken);
    }
    overrideBrowserWindowOpen();
    setHiddenHomepageModules(hiddenHomepageModules);
    setReorderedHomepageModules(reorderedHomepageModules);
};

/**
 * function check whether new breakpoint is enabled in TSE
 */
export const isNewBreakpointEnabledInTSE = (
    embedConfig: EmbedContextOptions,
) => {
    const { embedParams } = embedConfig;
    return !!embedParams?.enable2ColumnLayout;
};

const isBreakpointValid = (value?: number) => {
    return !(Number.isNaN(value) || value < BREAKPOINT_LOWER_BOUND);
};
const getVariableValue = (variable: string, defaultValue: number) => {
    const value = Math.floor(Number(getEmbedCssParams(variable)));
    return isBreakpointValid(value) ? value : defaultValue;
};

/**
 * function returns the liveboard responsive breakpoint value
 */
export const getEmbedResponsiveBreakpoints = (
    isLiveboardNewBreakPointsEnabled: boolean,
) => {
    if (!isLiveboardNewBreakPointsEnabled) {
        return {
            firstBreakpoint: FIRST_BREAKPOINT_DEFAULT_VALUE,
        };
    }
    const firstBreakpoint = getVariableValue(
        FIRST_BREAKPOINT_BREAKPOINT_VARIABLE,
        FIRST_BREAKPOINT_DEFAULT_VALUE,
    );
    const secondBreakpoint = getVariableValue(
        SECOND_BREAKPOINT_BREAKPOINT_VARIABLE,
        SECOND_BREAKPOINT_DEFAULT_VALUE,
    );
    if (firstBreakpoint < secondBreakpoint) {
        return {
            firstBreakpoint: FIRST_BREAKPOINT_DEFAULT_VALUE,
            secondBreakpoint: SECOND_BREAKPOINT_DEFAULT_VALUE,
        };
    }
    return {
        firstBreakpoint,
        secondBreakpoint,
    };
};

export const updateAnswerRuntimeParams = (
    parameters: Parameter[],
    currentEmbedParams: EmbedParams,
    cb: (dataTypes: string[], parameterIds: string[], values: string[]) => void,
) => {
    const dataTypes = [];
    const parameterIds = [];
    const values = [];

    for (let i = 1; i <= Object.keys(currentEmbedParams).length / 2; i++) {
        const paramKey = `param${i}`;
        const paramValKey = `paramVal${i}`;

        if (currentEmbedParams[paramKey]) {
            const paramName = currentEmbedParams[paramKey];
            const paramVal = currentEmbedParams[paramValKey];

            const filteredParam = parameters.find(
                (param: any) => param.name === paramName,
            );

            if (filteredParam) {
                dataTypes.push(filteredParam.dataType);
                parameterIds.push(filteredParam.id);
                values.push(paramVal);
            }
        }
    }

    cb(parameterIds, values, dataTypes);
};

export enum HomePageSearchBarMode {
    OBJECT_SEARCH = 'objectSearch',
    AI_ANSWER = 'aiAnswer',
    NONE = 'none',
}
